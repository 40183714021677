// 用于格式化时间
// 设置按需导出
export function formatDate(value, format) {
  //value: 需要格式化的数据
  //format: 指定格式 yyyy-MM-dd hh:mm:ss

  let date = new Date(value)
  // 获取年份
  let year = date.getFullYear()

  if (/(y+)/.test(format)) {
    // 获取匹配组的内容
    let content = RegExp.$1
    format = format.replace(content, year.toString().slice(4 - content.length))
  }

  let o = {
    // y: date.getFullYear(),  // 用这一句也行，但只适用于四位数显示时候用
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
  }

  for (let key in o) {
    // 构造动态正则
    let reg = new RegExp(`(${key}+)`)

    if (reg.test(format)) {
      // 获取匹配组的内容
      let content = RegExp.$1
      let k =
        o[key] >= 10 ? o[key] : content.length == 2 ? '0' + o[key] : o[key]
      format = format.replace(content, k)
    }
  }
  return format
}

export function textContent(val) {
  val = val.toString()
  return val.replace(val[0], val[0].toUpperCase())
}

//对象数组key值替换
export function changeKey(list, OldKey, NewKey) {
  let newList = list.map((item) => {
    return { [NewKey]: item[OldKey] }
  })
  return newList
}

export function numberFormat(number, decimals, dec_point, thousands_sep) {
  decimals = 2 //这里默认设置保留两位小数，也可以注释这句采用传入的参数
  /*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * */
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point
  var s = n.toString().split('.')
  var re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  } else {
    s[1] = s[1].substring(0, prec) //小数点位数超出长度时截取前面的位数
  }
  return s.join(dec)
}
