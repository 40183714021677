export const WingFrom = (item, returnUrl) => {
  let WingDom: String = `
  <form id="Wing" style="display: none" action="${item.Wing.WingUrl.toString()}" method="post">
        <input type="hidden" type="text" name="username" id="username"" placeholder="Username"
            value="${item.Wing.WingUserName.toString()}" />
        <input type="hidden" type="text" name="bill_till_number" id="bill_till_number""
            placeholder="Bill or Till Number" value="${item.Wing.WingBillerCode.toString()}" />
        <input type="hidden" type="checkbox" name="sandbox" value="${item.Wing.Sandbox ? 1 : 0}" checked="checked" />
        <input type="hidden" type="text" name="rest_api_key" id="rest_api_key""
            placeholder="rest_api_key" value="${item.Wing.WingAPIKey.toString()}" />
        <input type="hidden" type="text" name="amount" id="amount"" placeholder="Amount"
            value="${item.AccountAmount.toString()}" />
        <input type="hidden" type="text" name="attr1_name" id="attr1_name""
            placeholder="Attribute Name 1" />
        <input type="hidden" type="text" name="attr1_value" id="attr1_value""
            placeholder="Attribute Value 1" />
        <input type="hidden" type="text" name="attr2_name" id="attr2_name""
            placeholder="Attribute Name 2" />
        <input type="hidden" type="text" name="attr2_value" id="attr2_value""
            placeholder="Attribute Value 2" />
        <input type="hidden" type="text" name="return_url" id="return_url"" placeholder="Return URL"
            value="${returnUrl}" />
        <input type="hidden" type="text" name="remark" id="remark"" placeholder="Remark"
            value="${item.Wing.LODARechargeNo.toString()}" />
    </form>
  `
  return WingDom
}
